
import React, { useState } from "react"
import firebaseui from 'firebaseui'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ErrorComponent from "../../../../components/Error/Error";
import { message } from 'antd'
import loadingAnim from '../../../../assets/loading.svg'
import { getFirstLoginUserProfile, setFirstLoginUserProfile } from "../../../../utils/firebase/profile";
import { useLoadingState } from "../../../../contexts/loadingState";
import { auth, getUser } from "../../../../utils/firebase/firebase";
import firebase from "firebase/app"

const PortosaurusFirebaseAuth = () => {
  const [, dispatchLoadingState] = useLoadingState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const errorRoutine = async (err: any) => {
    message.error(`Something went wrong!`)
    setError(true)
    console.error(err)
  }

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //have to set up an api app for anything that's not google and email
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      }

    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential, redirectUrl) => {
        dispatchLoadingState({ loading: true })
        setLoading(true)

        // console.log("auth result: ", authResult)
        // console.log("redirectUrl: ", redirectUrl)
        // console.log("cur user id: ", firebase.auth().currentUser?.uid)

        /// we try to get the user first, if not, it means it's a new user
        try {
          const uid = authResult?.user?.uid
          if (!uid) throw new Error(`Unable to get uid`)
          getUser(uid).then((userDocFirestore) => {
            if (!userDocFirestore) {
              /// user is not found in firestore, means the firstlogin profile is not there yet
              /// we need to create and set it
              /// the onSnapshot will be responsible for dispatching state, so we need not worry
              getFirstLoginUserProfile(authResult)
                .then((userProfile) => {
                  if (!uid)
                    throw new Error(`Unable to get uid!`)

                  setFirstLoginUserProfile(uid, userProfile)
                    .then(() => {
                      setTimeout(() => dispatchLoadingState({ loading: false }), 1000)
                      // history.push(`/profile`)
                    })
                })
            }
            else {
              setTimeout(() => dispatchLoadingState({ loading: false }), 1000)
              // history.push(`/profile`)
            }
          })

        }
        catch (err) {
          dispatchLoadingState({ loading: false })
          errorRoutine(err)
        }
        return false
      },
      signInFailure: async (err) => {
        errorRoutine(err)
      },
      uiShown: () => {
        setLoading(false)
      }
    }
  }

  if (error) {
    return <ErrorComponent error="We couldn't sign you in. Please try again." />
  }

  return (
    <div>
      {loading && <img src={loadingAnim} alt="loading" className="loader" />}
      <div className={`${loading ? `hidden` : ``}`} >
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </div>
    </div>
  )
}

export default PortosaurusFirebaseAuth
